.imgcont{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;


}
.video-react .video-react-big-play-button {
      display: none !important;
    }
.langvideo{
      height: 100%;
      width:100%;
    }
    .langcont{
     height: 300px !important;
     width:380px !important;
     margin: 10px !important;
     /* margin: -10px; */
     /* position: fixed; */
    }

@media  only screen and (max-width: 1020px){
      .imgcont{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px;
      
      }

      .langvideo{
            height: 100%;
            width:100%;
          }
          .langcont{
           height: 300px !important;
           width:300px !important;
          
           
         
          }
}
