.imgcont{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px;

}
.video-react .video-react-big-play-button {
      display: none !important;
    }
.main-cont{
      width:100%;
      height: 100%;
      padding: 1px;

      align-items: center;
}
.allvideo-cont{
      height:250px !important;
      width: 400px !important;
      margin: 0.5%;

}
.videocard{
      margin: 0.5%;
      height:350px !important;
      width: 500px !important;
}
@media  only screen and (max-width: 968px){
      .imgcont{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 10px;
      
      }
      .main-cont{
            width:100%;
            height: 100%;
            /* padding: 10px; */
            
            align-items: center;
      }
      .videocard{
            height:300px !important;
            width: 460px !important;
            margin: 0.5%;
	    text-align:center;

      }
      .allvideo-cont{
            height: 200px !important;
            width: 360px !important;
            margin: 0.5%;
      
      }

}
