.homcont1{
      height: 300px;
      width:300px;
      margin: 20%;
      /* margin-bottom: 18%; */
}
.homvideo1{
      height: 20% !important;
           
    } 
    .imgcont{
      padding: 3%;
    }

    .homcont2{
      display: flex;
      justify-content: flex-start;
      /* background-color: rgb(110, 99, 111); */
      width:100%;
      padding: 2px;
    
}
.fixed-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

/* musicdetails css */
.imgcont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;

}
.slide {
  height: 100%;
}

.main-cont1{
  width:100%;
  height: 100%;
  padding: 2px;
  /* overflow-y: auto; */
  align-items: center;
  margin-bottom: -50px !important;
}
.allvideo-cont{
  height:250px;
  width: 400px;
  margin: 0.5%;

}
.videocard{
  margin: 0.5%;
  height:350px;
  width: 500px;
}
/* @media  only screen and ( min-width: 270px and max-width:390px){
  .next {
    font-size: 20px;
    top: 112px !important;
    position: absolute;
    right: 25% !important;
}

.prev {
  font-size: 20px;
  top: 112px !important;
  position: absolute;
  left: 25% !important;
} */

@media  only screen and ( max-width: 1020px){

      
.slick-prev:before, .slick-next:before {

    color: white !important;
    font-size: 30px !important;
  
    margin-bottom: 50px !important;
    top:36% !important;
    display: none !important;
  
  }
  
  .slick-prev:after, .slick-next:a
  fter {
   
    color: white !important;
    font-size: 30px !important;
    margin-bottom: 50px !important;
    top:36% !important;
    display: none !important;

  
  }

	.album {
    flex: 1 1;
    overflow-y: scroll;
    /* padding: 20px; */
    padding-top: 6% !important;
}
  slick-prev:before, .slick-next:before {

    color: white !important;
    font-size: 30px !important;
  
    margin-bottom: 50px !important;
    top:16% !important;
  
  }
  
  .slick-prev:after, .slick-next:a
  fter {
   
    color: white !important;
    font-size: 30px !important;
    margin-bottom: 50px !important;
    top:16% !important;
    
  
  }


  .next {
    font-size: 20px;
    top: 112px !important;
    position: absolute;
    right: 32% !important;
}

.prev {
  font-size: 20px;
  top: 112px !important;
  position: absolute;
  left: 32% !important;
}

  .songslist{
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  .mainimg {
        display: flex;
        height: 200px;
        width: 200px;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }

  .homcont2{
        display: flex;
        justify-content: flex-start;
        /* background-color: rgb(97, 67, 98); */
        width: 100vw;
        padding: 2px;
        margin-top: -22px;
  }
  
  .imgcont{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin: 10px; */
        margin: -1px;
        padding: -6px;
  
  }
  .main-cont1 {
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin: 0px;
        background-color: #000;
        border-radius: 8px;
        /* background-color: black; */
        margin-top: 10px;
    }
  /* .main-cont2{
        width:100%;
-57px!important        height: 100%;
        /* padding: 10px; */
        

  .videocard{
        height:300px;
        width: 460px;
        margin: 0.5%;

  }
  .allvideo-cont{
        height: 200px;
        width: 360px;
        margin: 0.5%;
  
  }

  .audiocont{
        width: 300px !important;
        height: 300px !important;
        display: flex !important;
        justify-content: center !important;
        /* align-items: center !important; */
        padding: 30px !important;
        /* position: fixed   ; */
        /* padding: 2; */
        /* margin: 10% !important; */
  }
  .audioimg{
        height: 300px !important;
        width:300px !important;
        
  }
  .audiowi{
        width:300px !important;
  }

}
.audiowi{
  width:500px !important;
}
.audioimg{
  height: 500px;
  width: 100%;
  
}
.audiocont{
  width: 500px !important;
  height: 700px !important;
  display: flex !important;
  justify-content: center !important;
  /* align-items: center !important; */
  padding: 30px !important;
  /* padding: 2; */
  /* margin: 10% !important; */
}


/* .homvideo1{
height: 20% !important;
width: 100% !important;
margin: -20% !important;
} */
/* .homcont2{
  display: flex;
  justify-content: space-around;
  background-color: rgb(146, 43, 150);
  width: 100vw;
  padding: 2px;
  margin: -45px;
} */

.imgcont
{
  margin: 10px;
}
.btun{
  width: 200px;
  background-color: blue;
  color: #fff;
  border-radius: 10px;
  border: none;
}



.mainimg {
  display: flex;
  height: 500px;
  width: 100vw;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.playdetails{
  display: flex;
  margin: 0px !important		;
}
.playdetails p{
margin-left: 10px;
}

.songslist{
  display: flex;
  margin: 10px;
}
      .audio-pa{
        width: 100vw !important;
      }




.rhap_main-controls-button {
    margin: 0 3px;
    color: #000;
    font-size: 35px;
    width: 35px;
    height: 35px;
    /* display:    ; */
}

.custom-audio-player .rhap_main-controls  span{

  background-color: transparent;
  color:#000;

  border: none;


}
.custom-audio-player   span{

  color:#000 ;
 
  border: none;

  background-color: transparent;


}

.custom-audio-player button{
  color: #000;
}
.rhap_main-controls button[aria-label="Rewind"],
.rhap_main-controls button[aria-label="Forward"] {
  display: none !important;
}
/* .rhap_button-clear rhap_main-controls-button rhap_rewind-button button{

  display: none !important;
}

.rhap_button-clear rhap_main-controls-button rhap_forward-button button{
  
  display: none !important;
} */


.rhap_container {
 
  background-color:transparent !important;

} 

  .next {
    font-size: 30px;
    top: 116px !important;
    position: absolute;
    right: 45%;
   
}

.prev {
  font-size: 30px;
  top: 116px !important;
  position: absolute;
  left: 45%;
 
}


.imgcont {
  margin: 2px !important;

}


.slick-prev:before, .slick-next:before {

  color: white !important;
  font-size: 30px !important;

  margin-bottom: 50px !important;
  top:36% !important;

}

.slick-prev:after, .slick-next:a
fter {
 
  color: white !important;
  font-size: 30px !important;
  margin-bottom: 50px !important;
  top:36% !important;
  

}
.slick-slide img{
  border-radius: none !important;
}







/* music */
.music-player {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.album {
  flex: 1;
  overflow-y: scroll;
  /* padding: 20px; */
  padding-top: 6% ;
padding-bottom:6% !important;
}



.audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  padding: 10px;

}

h3{
  margin-left: 20px;
}
.playdetails{
  display: flex;
  justify-content: flex-start;
}



/* musicplayer,header,imglogo,album,main,imgcont,slide,audio-player-container,playdetails */

    .imgcont{
      padding: 3%;
    }

    .homcont2{
      display: flex;
      justify-content: flex-start;
      /* background-color: rgb(110, 99, 111); */
      width:100%;
      padding: 2px;

}

/* musicdetails css */
.imgcont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;

}
.slide {
  height: 100% !important;
  width: 100% !important;
}

.main{
  width:100%;
  height: 100%;
  padding: 2px;
  /* overflow-y: auto; */
  align-items: center;
  margin-bottom: -30px !important;
}

@media  only screen and ( max-width: 1020px){


  .imgcont {
    margin: 2px !important;
    /* display: flex !important; */
  }
  .slick-prev:before, .slick-next:before {

    color: white !important;
    font-size: 30px !important;

    margin-bottom: 50px !important;
    top:36% !important;
    display: none !important;

  }

  .slick-prev:after, .slick-next:a
  fter {

    color: white !important;
    font-size: 30px !important;
    margin-bottom: 50px !important;
    top:36% !important;
    display: none !important;


  }
  .album {
    flex: 1 1;
    overflow-y: scroll;
    /* padding: 20px; */
    padding-top: 6% !important;
    padding-bottom:30% !important;
}
  slick-prev:before, .slick-next:before {

    color: white !important;
    font-size: 30px !important;

    margin-bottom: 50px !important;
    top:16% !important;

  }

  .slick-prev:after, .slick-next:a
  fter {

    color: white !important;
    font-size: 30px !important;
    margin-bottom: 50px !important;
    top:16% !important;


  }


  .next {
    font-size: 20px;
    top: 112px !important;
    position: absolute;
    right: 32% !important;
}

.prev {
  font-size: 20px;
  top: 112px !important;
  position: absolute;
  left: 32% !important;
}


  .imgcont{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin: 10px; */
        margin: -1px;
        padding: -6px;

  }
}
  .main {
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin: 0px;
        background-color: #000;
        border-radius: 8px;
        /* background-color: black; */
        margin-top: 10px;
    }


.imgcont
{
  margin: -22px !important;
}

.audio-player-container {
    background-color: transparent !important;
    bottom: 0;
    position: fixed;
    width: 100vw !important;
    opacity: 0.7 !important;
    background: #000 !important;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #000;
    font-size: 35px;
    width: 35px;
    height: 35px;
    /* display:    ; */
}

.custom-audio-player .rhap_main-controls  span{

  background-color: transparent;
  color:#000;

  border: none;


}
.custom-audio-player   span{

  color:#000 ;

  border: none;

  background-color: transparent;


}

.custom-audio-player button{
  color: #000;
}
.rhap_main-controls button[aria-label="Rewind"],
.rhap_main-controls button[aria-label="Forward"] {
  display: none !important;
}


￼   .rhap_container {
    background-color:  transparent !important;
    
    opacity: 0.7 !important;
    background: #000 !important;
}

  .next {
    font-size: 30px;
    top: 116px !important;
    position: absolute;
    right: 45%;

}

.prev {
  font-size: 30px;
  top: 116px !important;
  position: absolute;
  left: 45%;

}


.imgcont {
  margin-bottom:  8px !important;
  margin-left: -22px !important;
  margin-right: -22px !important;

  display: flex !important;
}

.slick-prev:before, .slick-next:before {

  color: white !important;
  font-size: 30px !important;

  margin-bottom: 50px !important;
  top:36% !important;

}

.slick-prev:after, .slick-next:a
fter {

  color: white !important;
  font-size: 30px !important;
  margin-bottom: 50px !important;
  top:36% !important;


}
.slick-slide img{
  border-radius: none !important;
}







/* music */
.music-player {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background-color: #fff;
  padding: 4px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.album {
  flex: 1;
  overflow-y: scroll;
  /* padding: 20px; */
  padding-top: 4% ;

}



.audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  padding: 10px;
}

h3{
  margin-left: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom:-13px !important;
}
h3 span {
  margin-top: 14px;
  margin-right: 18px;
}
.playdetails{
  display: flex;
  justify-content: flex-start;
padding-left:16px;
font-weight:bold;
font-size:14px;
  background-color: transparent !important;
  color:#fff !important;

}
.playdetails p{
  margin:1px;
}
