html {
  /* scroll-behavior: smooth; */
}
/* padding and border are included in the width and height */
* {
  box-sizing: border-box;
}

body {
  background-color: #000;
  color: #f9f9f9;
  font-family: Avenir-Roman, sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: #f9f9f9;
  /* removes underlines from Hyper-links */
  text-decoration: none;
}

/* minimum width should be 768px 
for larger desktop devices*/
@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
/* for medium screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}

/* for small mobile devices */
@media only screen and (max-width: 479px) {
  body {
    font-size: 14px;
  }
}
/* 
 The only keyword is used to prevent older browsers that do not support media queries with media features from applying the specified styles. */
