.nava {
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
      color:#fff;
      /* margin-right: 10%; */
      /* margin-left: 10%; */
      /* color: #000; */
      background-color: #a72f91;
  overflow: auto;
  white-space: nowrap;
}

.nava  a{
      display: inline-block;
      color: white !important;
      text-align: center;
      padding: 14px;
      text-decoration: none;
}.nava a:hover{
      background-color: #777;
}

.imglogo{
 /*    height: 60px;
//      width:140px*/
      border-radius: 2px;
/* font-size: 16px;
font-weight: bold !important;
color: aqua !important;
background-color: rgb(179, 173, 184);
padding: 5px; */
}

li{
 list-style-type: none !important;
 margin: 10px;
 
}
li a{
      color: #000 !important;
}
.headcnt{
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 10%;
      background: #ffffffe6;
      /* padding: 10px; */
      color:#000 !important;
}
.hed{
      color: rgb(24 141 187 / 98%);
      font-family:  BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      /* font-weight: bold; */
}
