.main-cont1{
 padding-left: 60px !important;
 padding-right: 60px !important;

 margin: 0px;
 background-color: #000;
 border-radius: 8px;
 /* background-color: black; */
}
.video-react .video-react-big-play-button {
  display: none !important;
}

.tag {
  text-align: center;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  /* display: flex; */
  /* justify-content: space-between; */
 
  /* color: #000; */
  width: 100%;
  }
  
  .tag h1{
    text-align: start;
    padding-left: 15px;
    /* padding-top: 1; */
    
  } 
  
  .tag h2{
    text-align: start;
    padding-right: 10px;
    
  } 
  /* .slick-prev:before, .slick-next:before{
     font-size: 25px !important;
  } */
  /* .slick-prev:before, .slick-next:after{
    font-size: 25px !important;
    color:blanchedalmond;
  } */

  .slick-slide img {
      /* margin: 10px !important; */
      /* width: 50%; */
      /* height: 50%; */
      height: 100% !important;
      padding: 2px !important;
      width: 100% !important;
      border-radius: 8px !important;
  }

  @media only screen and  (max-width: 1020px) {
    .main-cont1{
      padding-left: 30px !important;
      padding-right: 30px !important;
     
      margin: 0px;
      background-color: #000;
      border-radius: 8px;
      /* background-color: black; */
     }
    .slick-slide img {
        /* margin: auto; */
     padding:2px !important;
        height: 100% !important;
        width: 100% !important;
        border-radius: 8px !important;
    }
    .imgslider{
        height: 100%;
    }
/* .slick-prev:before, .slick-next:before{
      font-size:15px !important;
   } */
   .homvideo{
    height: 100%;
    width:100%;
  }
  .homcont{
   height: 300px !important;
   width:300px !important;
  
   margin: -10px !important;
 
  }
  .cont{
    display: flex;
    flex-direction: column;
    margin: 10px;
   }

  }
 .homvideo{
   height: 100% ;
   width:100%;
 }
 .homcont{
  height: 300px !important;
  width:380px!important;
  margin-bottom: 5% !important;
  /* margin: -10px; */
  /* position: fixed; */
 }
 .cont{
  display: flex;
  margin: 10px;
 }
.slick-list .slick-arrow .slick-next{
  right: 25px !important;
}
.slick-list .slick-arrow .slick-previous{
  left: 25px !important;
}
